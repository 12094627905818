import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import LogoAlt from "../logo-alt"
import Img from "gatsby-image/withIEPolyfill"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"

const Footer = () => {
  const data = useStaticQuery(graphql`
      query {
          company: directusCompany {
              legal_name
              oib
              partners {
                  link
                  logo {
                      localFile {
                          childImageSharp {
                              fixed(width: 100) {
                                  ...GatsbyImageSharpFixed
                              }
                          }
                      }
                  }
              }
          }
          company_address: directusCompanyAddress(language: {eq: "it"}) {
              address
          }
      }
  `)

  return (
    <footer>
      <Container fluid>
        <Row>
          <Col xs={12} md={6} lg={3} xl={2} className={"offset-xl-1 mb-2"}>
            <Navbar.Brand href="/it" style={{ maxWidth: `240px`, width: `inherit` }}>
              <LogoAlt />
            </Navbar.Brand>
            <p className={"mt-4 mb-1"}><b>{ data.company.legal_name }</b></p>
            { data.company_address.address.split('\n').map((line, key) => (
              <p className={"mb-0"} key={key}>{ line }<br/></p>
            ))}
            <p>OIB { data.company.oib }</p>
          </Col>

          <Col xs={12} md={6} lg={3} xl={2} className={"mb-2 my-2"}>
            <h6>Partners</h6>
            { data.company.partners.map((partner, key) => (
              <Nav.Link href={partner.link} key={key} className={"px-0"}>
                <Img fixed={partner.logo.localFile.childImageSharp.fixed} objectFit="fill" />
              </Nav.Link>
            ))}
          </Col>

          <Col xs={12} md={12} lg={5} xl={3} className={"offset-xl-2 mb-2"}>
            <Form name={"newsletter"} inline netlify-honeypot="bot-field" data-netlify="true">
              <input type="hidden" name="bot-field" />
              <Form.Group className={"flex-fill mx-2 mb-2"} controlId="email">
                <Form.Control className={"w-100"} name="email" type="email" placeholder="Iscriviti alla nostra newsletter" />
              </Form.Group>
              <Button className={"mx-2 mb-2"} variant="primary" type="submit">
                Invia
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
